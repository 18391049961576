import { AvailableChainId } from '@/configs/evmWalletConfigs';

export const networkContract: { [key: number]: { nftAddress: string; mintAddress: string } } = {
  [AvailableChainId.MANTA]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  },
  [AvailableChainId.MINT]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  },
  [AvailableChainId.CONFLUX]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  },
  [AvailableChainId.MORPH]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  },
  [AvailableChainId.IOTEX]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  },
  [AvailableChainId.JFIN]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  },
  [AvailableChainId.BSC]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0x135eED122737B21E3807fA935e794E06F7b20Ed2',
  },
  [AvailableChainId.OKX]: {
    nftAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
    mintAddress: '0x5139C296a3Ccc46B083B53083C86D4243762d8D1',
  },
  [AvailableChainId.POLYGON]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  },
  // [AvailableChainId.GM]: {
  //   nftAddress: '0x1be7219F0A90304618778c9Ac8175DA31371272C',
  //   mintAddress: '0xC485d45C4C83a95c691E5e600061E988C5085348',
  // },
  [AvailableChainId.BITLAYER]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  },
  [AvailableChainId.OPBNB]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  },
  // [AvailableChainId.ARBITRUM]: {
  //   nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
  //   mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  // },
  [AvailableChainId.BASE]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  },
  [AvailableChainId.LINEA]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0x135eED122737B21E3807fA935e794E06F7b20Ed2',
  },
  [AvailableChainId.VICTION]: {
    nftAddress: '0x135eed122737b21e3807fa935e794e06f7b20ed2',
    mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  },
  [AvailableChainId.BERA]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  },
  [AvailableChainId.ZETA]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  },
  [AvailableChainId.BEVM]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  },
  [AvailableChainId.BLAST]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  },
  [AvailableChainId.ASTAR]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  },
  [AvailableChainId.SEI]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  },
  [AvailableChainId.OP]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  },
  [AvailableChainId.KAIA]: {
    nftAddress: '0x3A0e405feA19d510D8FD919e91553b68068bCC01',
    mintAddress: '0xc99366dAb9aD05157a680FE5107baFf5b32D6214',
  },
};
