import styled from 'styled-components';
import { Row, StyledMaskIcon, fontStyle } from '@styles/common';
import { SM_AND_UP_QUERY } from '@/breakpoints';
import { Mode } from '../type';

export const StyledWrapper = styled.div<{ isShow: boolean; isTestnet: boolean; mode?: Mode }>`
  background: ${({ theme, mode }) => (mode === Mode.DARK ? theme.colors.black : theme.colors.white)};
  height: ${({ isTestnet }) => (isTestnet ? 'calc(100vh - 101px)' : 'calc(100vh - 60px)')};
  display: ${({ isShow }) => (isShow ? 'block' : 'none')};
  position: fixed;
  top: ${({ isTestnet }) => (isTestnet ? '101px' : '60px')};
  left: 0;
  z-index: 15;
  width: 100%;

  @media ${SM_AND_UP_QUERY} {
    height: auto;
    position: absolute;
    top: calc(100% + 28px);
    left: -30px;
    border: 1px solid ${({ theme }) => theme.colors.brightDream};
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 274px;
    height: 500px;
    overflow: auto;
    padding-bottom: 13px;
  }
`;
export const StyledList = styled(Row)`
  justify-content: flex-start;
  flex-direction: column;
  height: calc(100vh - 300px);
  overflow-y: auto;

  @media ${SM_AND_UP_QUERY} {
    justify-content: center;
    height: auto;
    overflow: visible;
  }
`;
export const StyledNetwork = styled(Row)`
  padding: 15px 0;
  width: 260px;
  position: relative;

  @media ${SM_AND_UP_QUERY} {
    width: 224px;
    padding: 8px 0 8px 9px;
  }
`;
export const StyledNetworkName = styled.div<{ isActive?: boolean; mode?: Mode }>`
  ${fontStyle('16px')}
  font-weight: ${({ isActive }) => (isActive ? '700' : 'normal')};
  color: ${({ isActive, theme, mode }) => {
    const color = mode === Mode.DARK ? theme.colors.white : theme.colors.black;
    return isActive ? theme.colors.brightDream : color;
  }};

  @media ${SM_AND_UP_QUERY} {
    ${fontStyle('12px')}
    font-weight: ${({ isActive }) => (isActive ? '700' : 'normal')};
  }
`;
export const StyledTitle = styled.div<{ mode?: Mode }>`
  ${fontStyle('16px', '700')}
  color: ${({ theme, mode }) => (mode === Mode.DARK ? theme.colors.white : theme.colors.black)};

  @media ${SM_AND_UP_QUERY} {
    ${fontStyle('12px', '700')}
  }
`;
export const StyledTitleWrapper = styled(Row)<{ hideElement?: boolean }>`
  width: 100%;
  padding: 31px 0 25px 29px;

  @media ${SM_AND_UP_QUERY} {
    display: ${({ hideElement }) => (hideElement ? 'none' : 'flex')};
    padding: 20px 0 6px 23px;
  }
`;
export const StyledCheckMark = styled(StyledMaskIcon)`
  position: absolute;
  left: -20px;

  @media ${SM_AND_UP_QUERY} {
    left: -17px;
  }
`;
export const StyledNetworkHover = styled(Row)`
  justify-content: center;
  width: 100%;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => `${theme.colors.brightDream}0c`};
  }

  @media ${SM_AND_UP_QUERY} {
    padding-left: 32px;
  }
`;
